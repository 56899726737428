import { Style } from '@solidjs/meta';
import { Component, JSX, createContext, useContext, createResource, Resource } from 'solid-js';
import { companyGateway } from '~/gateways/companyInfoGateway';
import { fallbackInfo, PresentableCompayStyle, presentCompanyInfo } from '~/presenters/CompanyStylePresenter';
import { getCurrentDomain } from '~/utils/domain';
interface CompanyStylingType {
  companyStyling?: Resource<PresentableCompayStyle>;
}

const CompanyStylingContext = createContext<CompanyStylingType>();

interface CompanyStylingProps {
  children: JSX.Element;
}

async function loadPresentableCompanyInfo() {
  try {
    const companyInfo = await companyGateway.getCompanyInfo(getCurrentDomain());
    return Object.assign({}, fallbackInfo, presentCompanyInfo(companyInfo));
  } catch (error) {
    console.log('Error loading company info:', error);
    return fallbackInfo;
  }
}

export const CompanyStyling: Component<CompanyStylingProps> = (props) => {
  const [companyStyling] = createResource(
    () => {
      return loadPresentableCompanyInfo();
    },
    {
      ssrLoadFrom: 'server',
      deferStream: true,
    }
  );

  return (
    <>
      <Style>
        {`
        :root {
          --primary-color: ${companyStyling()?.primaryColor};
          --hover-color: ${companyStyling()?.hoverColor};
          --active-color: ${companyStyling()?.activeColor};
          --gradient-color-start: ${companyStyling()?.gradientStartColor};
          --gradient-color-end: ${companyStyling()?.gradientEndColor};
        }
      `}
      </Style>
      <CompanyStylingContext.Provider value={{ companyStyling }}>{props.children}</CompanyStylingContext.Provider>
    </>
  );
};

export function useStyling() {
  const context = useContext(CompanyStylingContext);
  if (!context) {
    throw new Error('useStyling must be used within an CompanyStylingContext');
  }
  return context;
}
